<template>
  <div>
    <div v-if="isH5 === 1">
      <div class="container">
        <div class="input-box">
          <div class="logo-box">
            <img src="../../assets/among/logo.svg" alt="">
          </div>
          <div class="amount-input">
            <div class="company">
              <img src="../../assets/among/company.svg" alt="">
            </div>
            <input class="input" type="number" v-model="payTestAmount" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, &#39;$1&#39;)" onblur="b=this.value;b=(b+&#39;&#39;).replace(/^0+\./g,&#39;0.&#39;);b.match(/^0+[1-9]+/)?b=b.replace(/^0+/g,&#39;&#39;):b;this.value=Number(b)?b:0;">
          </div>
          <p class="tips-text">可在输入框内输入自定义金额</p>
        </div>
        <div class="dex-box">
          <div class="dex-text">
            <p style="margin-bottom: 30px; font-size: 20px; font-weight: 900">
              支付体验
            </p>
            <p>自定义金额体验Xiangfuq，支持支付宝付款</p>
          </div>
          <div class="pay-button" @click="showPopup(true)">
            <p>支付 ￥ {{payTestAmount}}</p>
          </div>
        </div>
      </div>
      <van-popup v-model="show"  position="bottom" :style="{ marginBottom: '20px',height: '210px',width: 'calc(100vw - 40px)',left: '20px',borderRadius: '16px' }" >
        <div class="pay-window">
          <div class="pay-box" @click="payTest()">
            <div class="alipay paytest">
              <div class="pay-item-box" id="alipay">
                <div class="icon">
                  <img src="../../assets/among/alipay-icon.svg" alt="alipay">
                </div>
                <p>支付宝支付</p>
              </div>
            </div>
          </div>
          <div class="cancel" @click="showPopup(false)">取消</div>
        </div>
      </van-popup>
    </div>
    <div class="none-show" v-if="isH5 === 2">
      <img src="../../assets/among/pc-tips.svg" alt="pc-tips">
      <p>该页面为H5支付体验，请在移动端打开</p>
    </div>
  </div>

</template>

<script>

import { payTestOrder } from '@/api/merchat'
export default {
  components: {
  },
  data () {
    return {
      payTestAmount: 0.01, // 支付金额，默认为0.01
      currentWayCode: "ALI_WAP", // 支付金额，默认为0.01
      isH5: 1, // 支付金额，默认为0.01
      appId: "", // appId
      mchNo: "", // mchNo
      show: false,
      mchOrderNo: '', // 模拟商户订单号
      currentPayDataType: 'payurl', // 支付参数
    }
  },
  mounted() {
    this.isMobile()
    console.log("进入among页面");
  },
  methods: {
    showPopup(type) {
      this.show = type;
    },
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        this.isH5 = 1;
      } else {
        this.isH5 = 2;
      }
    },
    // 立即支付按钮
    payTest () {
      // 判断支付金额是否为0
      if (!this.payTestAmount || this.payTestAmount === 0.00) {
        return this.$toast.fail({
          message: '请输入正确的金额',
          className: 'noticeWidth'   //所加样式名
        });
      }
      this.appId = this.$route.query.appId
      this.mchNo = this.$route.query.mchNo
      if (!this.appId || !this.mchNo) {
        return this.$toast.fail({
          message: '缺少参数',
          className: 'noticeWidth'   //所加样式名
        });
      }
      console.log(this.appId)
      this.mchOrderNo = 'M' + new Date().getTime() + Math.floor(Math.random() * (9999 - 1000) + 1000)

      const that = this
      payTestOrder({
        // jsapi 默认使用聚合二维码支付
        wayCode: this.currentWayCode, // 支付方式
        amount: this.payTestAmount, // 支付金额
        appId: this.appId, // appId
        mchNo: this.mchNo, // mchNo
        mchOrderNo: this.mchOrderNo, // 订单编号
        payDataType: this.currentPayDataType, // 支付参数（二维码，条码）
        orderTitle: '享付WAP支付'
      }).then(res => {
        //是否是微信浏览器
        if (! /(micromessenger)/i.test(navigator.userAgent)) {
          location.href = res.payData;
        }else {
          this.$router.push('/tips?payUrl='+escape(res.payData))
        }
      }).catch(res => {
        that.$toast.fail({
          message: res.msg,
          className: 'noticeWidth'   //所加样式名
        });
      })
    },
  },

}
</script>

<style lang="css" scoped>
@import './pay.css';
@import './public.css';
</style>
