import request from '@/http/request'

/** 支付体验下单配置 **/
export function payTestOrder (parameter) {
  return request.request({
    url: 'https://merchant.xiangfuq.com/api/pay/test/payOrders',
    method: 'POST',
    data: parameter
  })
}
